import { Link } from "gatsby"
import React, { useEffect } from "react"
import Layout from "../../components/layout"
import "./../../styles/privacy-policy.css"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="about-us-section-container">
        <div
          className="container-heading"
          style={{ textDecoration: "underline", textTransform: "uppercase" }}
        >
          Mooofarm Privacy Policy
        </div>
        <div className="terms-content-container">
          <div className="terms-section-container">
            <p className="terms-content-text">
              Welcome to MoooFarm, a platform aimed at empowering dairy farmers.
              This Platform is made available by MoooFarm Pvt. Ltd., (“
              <span className="terms-text-bold ">Company</span>”, “
              <span className="terms-text-bold ">we</span>”, “
              <span className="terms-text-bold ">our</span>” or “
              <span className="terms-text-bold ">us</span>”) . This privacy
              policy (“<span className="terms-text-bold ">Privacy Policy</span>
              ”) describes the information we collect and explains how we
              collect, use, disclose, and protect such information, and your
              choices about the collection and use of your information.
            </p>
            <p className="terms-content-text">
              But first, some points to note:{" "}
            </p>
            <ul className="a" style={{ listStyleType: "bullets" }}>
              <li>
                The (“
                <span className="terms-text-bold ">Section Highlights</span>” in
                each clause merely provides a highlight of the clause. Reading
                the highlights is not a replacement for reading the clause. In
                case of a conflict between the highlights and the clause, the
                clause will prevail.
              </li>
              <br />
              <li>
                We may revise this Privacy Policy as well as update the Platform
                or Services from time to time, so please keep visiting this page
                regularly to take notice of any changes we make.
              </li>
              <br />
              <li>
                This Privacy Policy applies to all users of our Platform or
                Services. This Privacy Policy is incorporated within and a part
                of our Terms of Use. Terms capitalised but not defined herein
                shall have the meaning assigned to them in the Terms of Use.
              </li>
              <br />
              <li>
                If you do not agree with the processing policies laid out in
                this Privacy Policy, you should not use our Platform or
                Services.
              </li>
              <br />
              <li>
                By using, visiting, or signing-up to our Services, you have
                agreed to this Privacy Policy.
              </li>
            </ul>
          </div>
          <ol style={{ listStyleType: "number", counterReset: "item" }}>
            <li>
              <h1 className="terms-content-text terms-text-bold upper-case">
                Data Collected and Use Cases
              </h1>
              <span className="terms-content-text terms-text">
                Section Highlights: In this section, we detail the data we
                collect from you, how we collect it, why we collect it, and whom
                we share your data with. We show you how we use each data point
                we collect/require access to, in this table.
              </span>
              <ol style={{ listStyleType: "number", display: "block" }}>
                <li>
                  <p className="terms-content-text terms-text">
                    We have listed the data we collect from you, and the purpose
                    for such collection, below:
                  </p>
                  <div id="myDIV">
                    <div class="item1 terms-text-bold privacy-table-text">
                      What we collect
                    </div>
                    <div class="item2 terms-text-bold privacy-table-text">
                      How we collect it
                    </div>
                    <div class="item3 terms-text-bold privacy-table-text">
                      Why we collect it
                    </div>
                    <div class="item4 terms-text-bold privacy-table-text">
                      Whom we share it with
                    </div>

                    <div class="item1 ">
                      <ul>
                        <li className="marginBottom">
                          <p>
                            {"\u2022"} Name;{"\n"}
                          </p>
                        </li>
                        <li className="marginBottom">
                          <p>{"\u2022"} Phone number;</p>
                        </li>
                        <li className="marginBottom">
                          <p>{"\u2022"} Photograph; and</p>
                        </li>
                        <li className="marginBottom">
                          <p>{"\u2022"} Address.</p>
                        </li>
                      </ul>
                    </div>
                    <div class="item2 ">
                      <ul style={{ listStyleType: "suqre" }}>
                        <li className="marginBottom">
                          The information you provide during sign-up; and
                        </li>
                        <li>From your use of the Services.</li>
                      </ul>
                    </div>
                    <div class="item3 ">
                      {/* 1. To create your Account; */}
                      <ul style={{ listStyleType: "suqre" }}>
                        <li className="marginBottom">
                          To create your Account;
                        </li>
                        <li className="marginBottom">
                          For delivery of cattle and products to your address;
                        </li>
                        <li className="marginBottom">
                          To facilitate secure payments;
                        </li>
                        <li className="marginBottom">
                          To help other users connect with you with regard to
                          purchase and sale of cattle; and
                        </li>
                        <li className="marginBottom">
                          To help you discover cattle in the surrounding area;
                          and
                        </li>
                        <li className="marginBottom">
                          Communicate with you (e.g., for customer support,
                          grievance redressal, marketing and news, updates on
                          the Services, offers and transaction/payments related
                          information).
                        </li>
                      </ul>
                    </div>
                    <div class="item4 ">
                      <ul style={{ listStyleType: "suqre" }}>
                        <li className="marginBottom">
                          We share your name and contact details with other
                          users when you post cattle for sale on the Platform.
                          This allows them to contact you if they’re interested
                          in making a purchase;
                        </li>
                        <li>
                          We also share your name and contact details with our
                          delivery partners. This helps them reach out to you
                          when delivering cattle and products.
                        </li>
                      </ul>
                    </div>

                    <div class="item5 ">
                      Payment information (credit/debit card, bank account
                      information, UPI ids, GST Number (if applicable), and mode
                      of transfers used and preferred).
                    </div>
                    <div class="item6 ">
                      <ul style={{ listStyleType: "suqre" }}>
                        <li className="marginBottom">
                          The information you provide during sign-up; and
                        </li>
                        <li>From your use of the Services.</li>
                      </ul>
                    </div>
                    <div class="item7 ">
                      <ul style={{ listStyleType: "suqre" }}>
                        <li className="marginBottom">
                          For processing payments; and
                        </li>
                        <li>Enabling your use of the Platform and Services.</li>
                      </ul>
                    </div>
                    <div class="item8 ">
                      <ul style={{ listStyleType: "suqre" }}>
                        <li className="marginBottom">
                          We may use payments providers such as Razorpay to
                          process your payments and share your data with such
                          providers. Please note, you will need to refer to
                          their terms of service and privacy policy in relation
                          to your use of their services.
                        </li>
                        <li>
                          In the event that you avail loans through our
                          partners, we may share your data with our lending
                          partners such as Blacksoil Capital Private Limited (“
                          <span className="terms-text-bold ">Blacksoil</span>”),
                          to process the loan.
                        </li>
                      </ul>
                    </div>

                    <div class="item8 ">
                      <span>Services usage data: {"\n"}</span>
                      <ul style={{ marginTop: 10 }}>
                        <li className="marginBottom">
                          <p>
                            {"\u2022"} Log information; and{"\n"}
                          </p>
                        </li>
                        <li className="marginBottom">
                          <p>
                            {"\u2022"} Usage data such as pages viewed and time
                            spent using the Services.
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div class="item8 ">
                      <ul style={{ listStyleType: "suqre" }}>
                        <li className="marginBottom">
                          From your usage of the Services. Log file information
                          is automatically reported by your browser each time
                          you access the Services. When you use our Services,
                          our servers automatically record certain log file
                          information. These server logs may include anonymous
                          information such as your web request, Internet
                          Protocol (“IP”) address, browser type, referring /
                          exit pages and URLs, number of clicks and how you
                          interact with links on the Services, domain names,
                          landing pages, pages viewed, and other such
                          information.
                        </li>
                        <li>
                          We may use third-party analytics providers such as
                          Google or Facebook analytics, to process certain
                          information under a contract in compliance with
                          applicable law.
                        </li>
                      </ul>
                    </div>
                    <div class="item8 ">
                      <ul style={{ listStyleType: "suqre" }}>
                        <li className="marginBottom">
                          We use this to personalise your experience on the
                          Services by drawing insights from your usage.
                        </li>
                        <li className="marginBottom">
                          We will directly collect analytics data, or use
                          third-party analytics tools, to help us measure
                          traffic and usage trends for the Services. These tools
                          collect information sent by your browser, including
                          the pages you visit and other information that assists
                          us in improving the Services.
                        </li>
                        <li className="marginBottom">
                          To provide customer support and run
                          diagnostics/troubleshooting/debugging.
                        </li>
                        <li className="marginBottom">
                          We may use the data so collected to improve our
                          Services by using techniques such as machine learning.
                          When we use machine learning, we either: (1) still
                          have a human being involved in the process (and so are
                          not fully automated); or (2) use machine learning in
                          ways that don’t have significant privacy implications.
                        </li>
                      </ul>
                    </div>
                    <div class="item8 ">
                      <ul style={{ listStyleType: "suqre" }}>
                        <li className="marginBottom">
                          We will not share this information except as provided
                          here and in Clauses 1.2, 1.3 and 1.4 (Data Collected
                          and Use Cases) of this Privacy Policy.
                        </li>
                        <li className="marginBottom">
                          We may share your services usage data and data you
                          provide us with entities like MixPanel, WebEngage,
                          AppsFlyer, Yellow.ai and Cloudinary.
                        </li>
                        <li className="marginBottom">
                          In the event that you avail loans through our
                          partners, we may share this data with our lending
                          partners such as BlackSoil, to process the loan.
                        </li>
                      </ul>
                    </div>

                    <div class="item8 ">
                      <span>Device Permissions: {"\n"}</span>
                      <ul style={{ marginTop: 10 }}>
                        <li className="marginBottom">
                          <p>{"\u2022"} Camera;</p>
                        </li>
                        <li className="marginBottom">
                          <p>{"\u2022"} Location;</p>
                        </li>
                        <li className="marginBottom">
                          <p>{"\u2022"} Files; and</p>
                        </li>
                        <li className="marginBottom">
                          <p>{"\u2022"} SMS.</p>
                        </li>
                      </ul>
                    </div>
                    <div>Permissions you grant while using the Services.</div>
                    <div class="item8 ">
                      <ul style={{ listStyleType: "suqre" }}>
                        <li className="marginBottom">
                          To provide you a seamless experience on our Services;
                        </li>
                        <li className="marginBottom">
                          To identify your location and help you discover
                          relevant cattle traders in your area;
                        </li>
                        <li className="marginBottom">
                          To click and upload photographs of cattle, for Vet
                          consultations, and for uploading a profile picture on
                          your Account; and
                        </li>
                        <li>
                          To automatically read OTPs when you login to your
                          Account.
                        </li>
                      </ul>
                    </div>
                    <div class="item8 ">
                      We do not share your device permissions. But we may share
                      your location with our delivery partners and other users
                      to help them reach your location.
                    </div>

                    <div class="item8 ">
                      <span>Information you provide: {"\n"}</span>
                      <ul style={{ marginTop: 10 }}>
                        <li className="marginBottom">
                          <p>{"\u2022"} During customer support interaction;</p>
                        </li>
                        <li className="marginBottom">
                          <p>{"\u2022"} As part of customer surveys; and</p>
                        </li>
                        <li className="marginBottom">
                          <p>
                            {"\u2022"} As part of user comments (e.g., while
                            engaging with MoooFarm Saba).{" "}
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div>
                      This information is directly collected from your
                      interaction with us.
                    </div>
                    <div class="item8 ">
                      <ul style={{ listStyleType: "suqre" }}>
                        <li className="marginBottom">
                          To improve our Services;
                        </li>
                        <li className="marginBottom">
                          To resolve disputes between users and between the
                          users and us; and
                        </li>
                        <li className="marginBottom">
                          To assist you with your queries and grievances.
                        </li>
                      </ul>
                    </div>
                    <div class="item8 ">
                      We will not share this information except as provided in
                      Clauses 1.2, 1.3 and 1.4 (Data Collected and Use Cases) of
                      this Privacy Policy.
                    </div>
                  </div>
                </li>
                <li className="marginTop">
                  We use the services of third party processors such as Amazon
                  Web Services to host user data. Transfers of data to these
                  third party processors are done under contract in compliance
                  with applicable law. We do not generally transfer your
                  personal data outside your jurisdiction of residence. In the
                  event that we do so, we do it in compliance with applicable
                  law.
                </li>
                <li className="marginTop">
                  We may use or share or provide access to your data (as listed
                  above) to meet legal and compliance related requirements. We
                  may use the data we collect to investigate or address claims
                  or disputes relating to use of our Services, or as otherwise
                  allowed by applicable law, or as requested by regulators,
                  government entities, and official inquiries.
                </li>
                <li className="marginTop">
                  While negotiating or in relation to a change of corporate
                  control such as a restructuring, merger or sale of our assets,
                  we may have to disclose our databases and information we have
                  stored in our course of operations.
                </li>
              </ol>
            </li>

            <li className="marginTop">
              <h1 className="terms-content-text terms-text-bold upper-case ">
                How we store, secure, or transfer your data
              </h1>
              <span className="terms-content-text terms-text">
                Section Highlights: Briefly, we use industry best practices to
                protect your personal data.
              </span>
              <ol style={{ listStyleType: "number", display: "block" }}>
                <li>
                  <span className="terms-content-text terms-text">
                    {" "}
                    We use appropriate technical and organizational security
                    measures to ensure a level of protection for personal data
                    appropriate to the risk:
                  </span>
                  <ul>
                    <li>
                      <div style={{ flexDirection: "row" }}>
                        <span>{"\u2022"} </span>
                        <span className="terms-content-text terms-text">
                          We maintain a security and authorisation policy for
                          access to our systems and update it from time to time.
                        </span>
                      </div>

                      <div style={{ flexDirection: "row" }}>
                        <span>{"\u2022"} </span>
                        <span
                          className="terms-content-text terms-text"
                          style={{ textAlign: "justify" }}
                        >
                          The transfer of personal data between your end device
                          and us is generally carried out in an encrypted form
                          over secured HTTPS connection. You can identify an
                          encrypted connection for example by the lock symbol in
                          the address line of your browser.
                        </span>
                      </div>

                      <div style={{ flexDirection: "row" }}>
                        <span>{"\u2022"} </span>
                        <span className="terms-content-text terms-text">
                          We maintain strict access controls to prevent
                          unauthorized access to personal data.
                        </span>
                      </div>
                      <div style={{ flexDirection: "row" }}>
                        <span>{"\u2022"} </span>
                        <span className="terms-content-text terms-text">
                          If you communicate with us by e-mail, access by third
                          parties cannot be ruled out. In the case of
                          confidential information, we recommend using the
                          physical mail or encrypted e-mail communication (PGP).
                        </span>
                      </div>
                      <div style={{ flexDirection: "row" }}>
                        <span>{"\u2022"} </span>
                        <span className="terms-content-text terms-text">
                          All our systems responsible for handling user data are
                          equipped with firewalls to prevent unauthorized access
                          and breach.
                        </span>
                      </div>
                      <p className="terms-content-text terms-text">
                        However, do note that no method of transmission over the
                        internet, or method of electronic storage
                        is entirely secure and reliable, and we cannot guarantee
                        its absolute security.
                      </p>
                    </li>
                  </ul>
                </li>
                <li className="terms-content-text terms-text">
                  In the event that any information under our control is
                  compromised as a result of a breach of security, we will take
                  reasonable steps to investigate the situation and where
                  appropriate, notify those individuals whose information may
                  have been compromised and take other steps, in accordance with
                  any applicable laws and regulations.
                </li>
              </ol>
            </li>

            <li>
              <h1 className="terms-content-text terms-text-bold upper-case">
                Your rights{" "}
              </h1>
              <span className="terms-content-text terms-text">
                Section Highlights: Simply put, our use, access, collection, and
                sharing of your data, depends largely on you. We respect and
                abide by your preferences to extent possible, without affecting
                the ability to meet our legal obligations.
              </span>
              <ol
                style={{ listStyle: "number" }}
                className="terms-content-text"
              >
                <li>
                  <span style={{ textDecoration: "underline" }}>
                    Data Access and Portability:
                  </span>
                  <p>
                    {" "}
                    You have the right to request a copy of your personal data
                    being processed and/or retained by us. In certain instances
                    (mentioned below), you also have the right to request copies
                    of personal information that you have provided to us in a
                    structured, commonly used, and machine-readable format
                    and/or request us to transmit this information to another
                    service provider (where technically feasible). The instances
                    where you may request a copy of your personal information
                    include:
                  </p>

                  <ul style={{ listStyle: "lower-alpha" }}>
                    <li>
                      <p>Where you have provided personal information to us;</p>
                    </li>
                    <li>
                      <p>
                        Where processing of your personal information has been
                        done by automated means; and
                      </p>
                    </li>
                    <li>
                      <p>
                        Where the processing was based on your consent or for
                        the performance or a contract.
                      </p>
                    </li>
                  </ul>
                </li>
                <li className="marginTop">
                  <span style={{ textDecoration: "underline" }}>
                    Rectification:
                  </span>
                  <p>
                    {" "}
                    You have the right to ask us to correct inaccurate or
                    incomplete personal information about you, where you cannot
                    update it yourself within your Account.
                  </p>
                </li>

                <li className="marginTop">
                  <span style={{ textDecoration: "underline" }}>Erasure</span>

                  <ul style={{ listStyle: "lower-alpha" }}>
                    <li>
                      <p>
                        We generally retain your personal information for the
                        reasons and time periods specified under Clause 4 (How
                        Long We Keep Your Data) below
                      </p>
                    </li>
                    <li>
                      <p>
                        You have the right to ask us to delete your personal
                        information, subject to data retention requirements
                        under applicable laws, and for us to meet our legal
                        obligations. Please note that multiple legal bases for
                        may exist for retention of data. If you ask us to delete
                        your personal information, we will not be able to
                        provide you some or all of our Services, depending on
                        the nature of data requested for deletion.
                      </p>
                    </li>
                  </ul>
                </li>
                <li className="marginTop">
                  <span style={{ textDecoration: "underline" }}>
                    Withdrawing Consent:
                  </span>
                  <p>
                    You can withdraw your consent at any time by sending a
                    communication to us specifying which consent you are
                    withdrawing. Please note that the withdrawal of your consent
                    does not affect the lawfulness of any processing activities
                    based on such consent before its withdrawal.
                  </p>
                </li>
                <li className="marginTop">
                  <span style={{ textDecoration: "underline" }}>
                    Restriction of Processing:
                  </span>
                  <p>
                    You have the right to limit the ways in which we use your
                    personal information, in particular where (i) you contest
                    the accuracy of your personal information; (ii) the
                    processing is unlawful and you oppose the erasure of your
                    personal information; (iii) we no longer need your personal
                    information for the purposes of the processing, but you
                    require the personal information for the establishment,
                    exercise or defence of legal claims; or (iv) you have
                    objected to the processing pursuant to Section 3.6.
                    (Objection to Processing) and pending the verification
                    whether our legitimate grounds override your own.
                  </p>
                </li>
                <li className="marginTop">
                  <span style={{ textDecoration: "underline" }}>
                    Objection to Processing
                  </span>
                  <ol style={{ listStyle: "dot" }} className="marginTop">
                    <li>
                      You have the right to object to the processing of your
                      personal information based on grounds specific to your
                      situation if such processing is for direct marketing or is
                      for a purpose based on a legitimate interest or public
                      interest. If you object to processing based on legitimate
                      or public interests, we will no longer process your
                      personal information for these purposes unless we can
                      demonstrate compelling legitimate grounds for such
                      processing or where the processing is otherwise required
                      for the establishment, exercise or defence of legal
                      claims.
                    </li>
                    <li>
                      Where your personal information is processed for direct
                      marketing purposes, you may, at any time ask us to cease
                      processing your data for these direct marketing purposes
                      by sending an e-mail to{" "}
                      <a href="mailto:aashna.singh@mooo.farm">
                        {" "}
                        <span
                          style={{ color: "blue", textDecoration: "underline" }}
                        >
                          aashna.singh@mooo.farm
                        </span>{" "}
                      </a>
                      .
                    </li>
                  </ol>
                </li>
                <li className="marginTop">
                  <span style={{ textDecoration: "underline" }}>
                    Lodging Complaints:
                  </span>
                  <p>
                    You have the right to lodge complaints about our data
                    processing activities by filing a complaint with our
                    Grievance Officer as per Clause 5 (Our Communications).
                  </p>
                </li>
                <li className="marginTop">
                  <span style={{ textDecoration: "underline" }}>
                    Opting-out:
                  </span>
                  <p>
                    You have the right to opt-out of receiving marketing
                    communications from us. This includes communications on
                    offers and promotions based on your use of Services and
                    preferences.
                  </p>
                </li>
                <li className="marginTop">
                  <span style={{ textDecoration: "underline" }}>
                    Do Not Track:
                  </span>
                  <p>
                    Finally, because there is no common understanding on what a{" "}
                    <Link to="https://en.wikipedia.org/wiki/Do_Not_Track">
                      <span
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        Do Not Track
                      </span>{" "}
                    </Link>
                    signal is supposed to mean, we don’t respond to those
                    signals in any particular way.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <h1 className="terms-content-text terms-text-bold upper-case ">
                How long we keep your data{" "}
              </h1>
              <span className="terms-content-text terms-text">
                We retain personal information for as long as needed or
                permitted in light of the purpose(s) for which it was obtained
                and consistent with applicable law. The criteria used to
                determine our retention periods include, without limitation:
              </span>
              <ul
                className="terms-content-text"
                style={{ listStyleType: "bullets" }}
              >
                <li>
                  the length of time we have an ongoing relationship with you
                  and provide the Services to you (for example, for as long as
                  you have an account with us or keep using Services);
                </li>
                <li>
                  whether there is a legal obligation to which we are subject
                  (for example, certain laws require us to keep records of your
                  transactions for a certain period of time before we can delete
                  them); and/or
                </li>
                <li>
                  whether retention is advisable in light of our legal position
                  (such as in regard to applicable statutes of limitations,
                  litigation or regulatory investigations).
                </li>
              </ul>
            </li>
            <li>
              <h1 className="terms-content-text terms-text-bold upper-case">
                Our Communications{" "}
              </h1>
              <ul
                className="terms-content-text"
                style={{ listStyleType: "bullets" }}
              >
                <li>
                  We may from time to time send you Services-related
                  announcements when we consider it necessary to do so (such as
                  when we temporarily suspend the Services for maintenance, or
                  security, privacy, or administrative-related communications).
                  We send these to you via SMS or email, as we deem fit. You may
                  not opt-out of such Service-related communications.
                </li>
                <li>
                  The Platform and Services are made available by MoooFarm Pvt.
                  Ltd., having its registered office at WeWork 32nd Milestone,
                  Galaxy Hotel, NH-8, Sector 15 Part 2, Gurugram, Haryana 122001
                  - India. Our grievance officer is Aashna Singh, accessible via
                  email at:
                  <a href="mailto:aashna.singh@mooo.farm">
                    {" "}
                    <span
                      style={{ color: "blue", textDecoration: "underline" }}
                    >
                      aashna.singh@mooo.farm
                    </span>{" "}
                  </a>{" "}
                  (“<span className="terms-text-bold ">Grievance Officer</span>
                  ”). You can contact our data protection officer confidentially
                  by email to enquire about the treatment of your data,
                  including the deleting of your personal data as identified in
                  this document.
                </li>
              </ul>
            </li>

            <li>
              <h1 className="terms-content-text terms-text-bold upper-case ">
                Children’s Data{" "}
              </h1>
              <ul
                className="terms-content-text"
                style={{ listStyleType: "bullets" }}
              >
                <li>
                  Our Services, are not directed at individuals below the age of
                  18 years (“<span className="terms-text-bold ">Minors</span>”).
                  Minors are not permitted to sign up by themselves to the
                  Platform. We do not knowingly collect or solicit personal
                  information from Minors.
                </li>
                <li>
                  In the event that we learn that we have collected personal
                  information from a Minor without verification of parental
                  consent where this is required, we will delete that
                  information as quickly as possible
                </li>
                <li>
                  If you believe that we might have any information from or
                  about a Minor, please contact our Grievance Officer as per
                  Clause 5 (Our Communications).
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
